const _jsxFileName = "/usr/src/app/src/js/components/customer/src/components/AdminPages/CustomerAdminComponent.tsx";import React from "react";

import { AdminLayout } from "@opendash/core";
import { ParseSetView } from "@opendash/plugin-parse";
import { createComponent } from "@opendash/state";



export const CustomerAdminComponent = createComponent(
  function CustomerAdminComponent() {
    return (
      React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , React.createElement(ParseSetView, {
          className: "OD3_Customer",
          view: 
            {
              type: "table",
            } 
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        )
      )
    );
  }
);
