

import { $parse } from "@opendash/plugin-parse";
import { CustomerAdminComponent } from "./components/AdminPages/CustomerAdminComponent";





export class CustomerPlugin  {
    __init() {this.name = "CustomerPlugin"}

     __init2() {this.options = {
      
    }}

  constructor(options) {;CustomerPlugin.prototype.__init.call(this);CustomerPlugin.prototype.__init2.call(this);
    this.options = options || {};
  }

    async onFactory(factory) {
        factory.registerRoute({
          path: "/admin/parse/customer",
          props: this.options,
          component: async () => ({ default: CustomerAdminComponent }),
          permission: "parse-admin",
        });


        factory.registerStaticNavigationGroup({
          label: "Kunden und Partner",
          order: 15,
          id: "customer/entities",
        });


        factory.registerStaticNavigationItem({
          id: "admin/customeradmin",
          group: "customer/entities",
          place: "sidebar",
          order: 10,
          label: "Kunden",
          icon: "fa:building",
          link: "/admin/parse/customer",
          routeCondition: "/admin/**",
          activeCondition: "/admin/parse/customer",
          permission: "parse-admin",
        });

        $parse.ui.setClassConfig({
          className: "OD3_Customer",
          disableACLEditing: true,
          titleFields: ["Name"],
          displayFields: ["Kundennummer", "Name", "Strasse", "Postleitzahl", "Ort", "Land"],
          createFields: ["Name", "Strasse", "Postleitzahl", "Ort", "Land"],
          editFields: ["Name", "Strasse", "Postleitzahl", "Ort", "Land"]
        });
        

        // Translations:
        factory.registerTranslationResolver(
          "en",
          "researchproject",
          async () => await import("./translations/en.json")
        );
    
        factory.registerTranslationResolver(
          "de",
          "researchproject",
          async () => await import("./translations/de.json")
        );
      }
    }